// Importe os componentes necessários
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './componentes/home.js';
import PdfLaudo from './componentes/pdf_laudo/pdfLaudo.js';
import BuscarLaudo from './componentes/BuscarLaudo.js';


// Defina suas rotas dentro do componente App
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/certificado/:numser" element={<PdfLaudo />} />

         <Route path="/laudo" element={<BuscarLaudo />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
