import React, { useState } from 'react';
import { FaNewspaper } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Verificar from '../services/consultaApi'; // Corrigido
import '../css/buscarLaudo.css';




function BuscarLaudo() {
  const [selectedOption, setSelectedOption] = useState('serieEpi');
  const [inputBusca, setInputBusca] = useState('');
  const [laudos, setLaudos] = useState([]); // Corrigido

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
    setInputBusca('');
  };

  const handleInputChange = (event) => {
    setInputBusca(event.target.value);
  };

  const handleVerificar = async () => {
    const data = await Verificar(inputBusca, selectedOption);
    if (data) {
      setLaudos(data);
      window.scrollTo({ bottom: 100, behavior: 'smooth' }); 
    }
  };


  return (
    <div className="BuscarLaudo">
      <h2>Laudos Técnicos</h2>
      <hr className="linha" />
      <p>Opções de pesquisa (somente uma opção é selecionável)</p>
      <div>
        <input
          type="checkbox"
          id="numerolaudo"
          className="numerolaudo"
          value="numerolaudo"
          checked={selectedOption === 'numerolaudo'}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="numerolaudo">Número do laudo</label>
      </div>
      <div>
        <input
          type="checkbox"
          id="serieEpi"
          className="serieEpi"
          value="serieEpi"
          checked={selectedOption === 'serieEpi'}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="serieEpi">N° de série do EPI</label>
      </div>
      <div className="containerBusca">
        <input
          type="text"
          id="inputNumero"
          className="inputNumero"
          value={inputBusca}
          placeholder={selectedOption === 'numerolaudo' ? 'Digite o número do Laudo' : 'Digite o número de Série do EPI'}
          onChange={handleInputChange}
        />
        <input
          type="button"
          id="verificar"
          className="verificar"
          onClick={handleVerificar}
          value="Verificar"
        />
      </div>
      <div className="gridContainer">
        <div className="gridLaudoHeader">
          <span>OF</span>
          <span>Mão Direita</span>
          <span>Mão Esquerda</span>
          <span>Produto</span>
          <span className='descPro'>Descrição do Produto</span>
          <span>Visualizar</span>
        </div>
        {laudos.length > 0 ? (
          laudos.map((laudo, index) => (
            <div className="gridLaudo" key={index}>
              <span>{laudo.numlib}</span>
              <span>{laudo.numser}</span>
              <span>{laudo.numsec}</span>
              <span>{laudo.codpro_1}</span>
              <span className='descPro'>{laudo.despro_1} {laudo.despro_2}</span>
              <span>
                <Link to={{
                  pathname: `/certificado/${laudo.numser}` // Passando o objeto laudo como estado
                  }}>
                  <FaNewspaper className='iconVisualizar'/>
                </Link>            
              </span>
            </div>
          ))
        ) : (
          <div className="gridLaudo">
            <span>Nenhum laudo encontrado.</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default BuscarLaudo;
